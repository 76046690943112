import React, { PureComponent, useEffect, useMemo, useState } from "react";

import t from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line,
  BarChart,
  Bar,
  Rectangle,
  //   ResponsiveContainer,
} from "recharts";
import api from "../../infra/api";
import useWindowDimensions from "../../hooks/windowDimensions";

export default function FailedTestsChart({ project, maxed, offsetDays }) {
  const [data, setData] = useState([]);

  const { height, width } = useWindowDimensions();
  const dimensions = useMemo(() => {
    return maxed
      ? { width: width * 0.8, height: height * 0.5 }
      : { width: 500, height: 200 };
  }, [maxed, height, width]);

  useEffect(() => {
    console.log("fetch stats");
    api.projects
      .failedTests(project.id, offsetDays)
      .then((response) => {
        console.log("stats response", response);
        setData(response.data);
      })
      .catch((error) => {
        console.error("fetch stats error", error);
      });
  }, [project, offsetDays]);
  return (
    // <ResponsiveContainer width="100%" height="100%">
    <BarChart
      width={dimensions.width}
      height={maxed ? 500 : 200}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="failed" fill="#AA4A44" activeBar={<Rectangle fill="red" stroke="blue" />} />
          {/* <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
    </BarChart>
  );
}
