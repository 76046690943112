import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import { Button, Col, Flex, Input, Row, Select, Form, Switch } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import UseForm from "../hooks/useForm";
import { useEffect, useState } from "react";
import api from "../infra/api";

export default function Dashboard({ user, setUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoaging, setPageLoading] = useState(true);
  const { form, formHelpers, formFields } = UseForm({
    NAME: "name",
    DESCRIPTION: "description",
    PRIVATE: "private",
    TAGS: "tags",
  });
  const [project, setProject] = useState({
    name: "wip dev",
  });

  useEffect(() => {
    console.log("fetch project details");
    // fetch project details
    setPageLoading(true);
    api.projects
      .project(id)
      .then((response) => {
        console.log("project details response", response);
        setTimeout(() => {
          setPageLoading(false);
          setProject(response.data);
          form.setFieldValue(formFields.NAME, response.data.name);
          form.setFieldValue(
            formFields.DESCRIPTION,
            response.data.description ?? ""
          );
          form.setFieldValue(formFields.PRIVATE, response.data.private);
          form.setFieldValue(formFields.TAGS, response.data.tags);
        }, 500);
      })
      .catch((error) => {
        console.error("fetch project details error", error);
        setPageLoading(false);
        if (error.response.status === 401) {
          setUser(null);
        } else if (error.response.status === 404) {
          // project not found
          navigate("/404");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const boxStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    // border: "3px solid #ffafcc",
    marginBottom: 10,
    padding: 10,
  };
  const [projectTags, setProjectTags] = useState([]);
  const fetchTags = () => {
    console.log("fetchTags...");
    api.projects.tags().then((res) => {
      console.log("tags response", res.data);
      setProjectTags(
        res.data.map((tag) => ({
          value: tag,
          label: tag,
        }))
      );
    });
  };
  useEffect(() => {
    fetchTags();
  }, []);
  return (
    <div>
      {pageLoaging && <h1>{t(TRANSLATIONS.LOADING.key)}</h1>}
      {project && !pageLoaging && (
        <Row>
          <Col span={24}>
            <h1>
              {t(TRANSLATIONS.EDIT_PROJECT.key)} {project.name}
            </h1>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={(values) => {
                console.log("onFinish", values);
                setLoading(true);
                api.projects
                  .editProject(id, values)
                  .then((response) => {
                    setTimeout(() => {
                      console.log("edit project response", response);
                      setLoading(false);
                      // navigate(0)
                      navigate("/projects/" + response.data.id);
                    }, 500);
                  })
                  .catch((error) => {
                    setLoading(false);
                    if (error?.response.status === 400) {
                      formHelpers.setApiErrors(error.response.data);
                      // showNotification(
                      //   "error",
                      //   "Niepoprawne dane",
                      //   `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
                      // );
                    } else if (error?.response.status >= 500) {
                      // showNotification(
                      //   "error",
                      //   "Błąd serwera",
                      //   `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
                      // );
                      navigate("/500");
                    } else if (error?.response.status === 403) {
                      // showNotification(
                      //   "error",
                      //   "Brak uprawnień",
                      //   `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
                      // );
                      navigate("/403");
                    }
                  });
              }}
            >
              <Form.Item
                label={t(TRANSLATIONS.PRIVATE.key)}
                name={formFields.PRIVATE}
                id={formFields.PRIVATE}
                initialValue={false}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={t(TRANSLATIONS.PROJECT_NAME.key)}
                name={formFields.NAME}
                id={formFields.NAME}
                rules={[
                  {
                    validator: (_, value) => {
                      formHelpers.resetFieldGuiError(formFields.NAME);
                      if (value) {
                        return Promise.resolve();
                      } else {
                        formHelpers.setFieldGuiError(
                          formFields.NAME,
                          t(TRANSLATIONS.FIELD_REQUIRED.key)
                        );
                        return Promise.reject();
                      }
                    },
                  },
                ]}
                validateStatus={
                  formHelpers.errorFields.includes(formFields.NAME)
                    ? "error"
                    : null
                }
                help={
                  formHelpers.errorFields.includes(formFields.NAME)
                    ? formHelpers.getFieldErrors(formFields.NAME).join(", ")
                    : null
                }
                normalize={(value) => value.trimStart()}
              >
                <Input maxLength={80} disabled={loading} showCount />
              </Form.Item>

              <Form.Item
                label={t(TRANSLATIONS.DESCRIPTION.key)}
                name={formFields.DESCRIPTION}
                id={formFields.DESCRIPTION}
                rules={[
                  {
                    validator: (_, value) => {
                      formHelpers.resetFieldGuiError(formFields.DESCRIPTION);
                      if (value) {
                        return Promise.resolve();
                      } else {
                        formHelpers.setFieldGuiError(
                          formFields.DESCRIPTION,
                          t(TRANSLATIONS.FIELD_REQUIRED.key)
                        );
                        return Promise.reject();
                      }
                    },
                  },
                ]}
                validateStatus={
                  formHelpers.errorFields.includes(formFields.DESCRIPTION)
                    ? "error"
                    : null
                }
                help={
                  formHelpers.errorFields.includes(formFields.DESCRIPTION)
                    ? formHelpers
                        .getFieldErrors(formFields.DESCRIPTION)
                        .join(", ")
                    : null
                }
                normalize={(value) => value.trimStart()}
              >
                <Input.TextArea
                  showCount
                  rows={4}
                  maxLength={500}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item
                label={t(TRANSLATIONS.TAGS.key)}
                name={formFields.TAGS}
                id={formFields.TAGS}
              >
                <Select
                  mode="tags"
                  placeholder={t(TRANSLATIONS.TAGS.key)}
                  disabled={loading}
                  options={projectTags}
                />
              </Form.Item>

              <Flex
                style={boxStyle}
                align={"center"}
                justify={"flex-end"}
                gap={"large"}
              >
                <Link to={`/projects/${id}/`}>
                  <Button type="dark">{t(TRANSLATIONS.CANCEL.key)}</Button>
                </Link>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t(TRANSLATIONS.SAVE.key)}
                </Button>
              </Flex>
            </Form>
          </Col>
        </Row>
      )}
      {/* <h1>{t(TRANSLATIONS.PROJECT_LIST.key)}</h1>
      <ProjectsTableSection user={user} path={"/projects"} /> */}
    </div>
  );
}
