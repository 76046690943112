import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import api from "../infra/api";
import { Button, Col, Empty, Flex, Row, Table, Tag } from "antd";
import { Collapse } from "antd";
import { Avatar, List } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CheckOutlined, DeleteOutlined, LeftOutlined } from "@ant-design/icons";
export default function ReportDetailsPage({ user, setUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  useEffect(() => {
    console.log("fetch reprot details");
    // fetch project details
    setLoading(true);
    api.reports
      .report(id)
      .then((response) => {
        console.log("report details response", response);
        setTimeout(() => {
          setLoading(false);
          setReport(response.data);
          api.reports.reportComments(id).then((response) => {
            console.log("report comments response", response);
            setComments(response.data.results);
          });
        }, 500);
      })
      .catch((error) => {
        console.error("fetch report details error", error);
        setLoading(false);
        if (error.response.status === 401) {
          setUser(null);
          // TODO: if 403
        } else if (error.response.status === 404) {
          // project not found
          navigate("/404");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allTests = useMemo(() => {
    if (!report) return [];
    if (report.type === "PYTEST") {
      return report.data.tests;
    }
    const specs = report.data.results;
    console.log("specs", specs);
    const result = specs;
    console.log("all tests", result);
    return result;
  }, [report]);
  const TestCommentsComponent = ({ test, comments, reportType }) => {
    const testComments = useMemo(() => {
      const data = comments.filter((comment) => {
        return comment.test_uuid === test.uuid;
      });
      return data;
    }, [comments, test]);
    const [addMode, setAddMode] = useState(false);
    const [newComment, setNewComment] = useState("");
    const submitComment = () => {
      console.log("submit comment", newComment);
      if (newComment.trim() === "") {
        console.log("empty comment");
        return;
      }
      const uid = reportType === "PYTEST" ? test.uuid : test.uuid;
      console.warn("reportType", reportType, "test", test);
      api.reports.addTestComment(id, uid, newComment).then((response) => {
        console.log("add comment response", response);
        setComments([response.data, ...comments]);
        setNewComment("");
        setAddMode(false);
      });
    };
    const [deleteComment, setDeleteComment] = useState(null);
    const apiDeleteComment = (id) => {
      console.log("delete comment", id);
      api.reports.deleteTestComment(id).then((response) => {
        console.log("delete comment response", response);
        setComments(comments.filter((comment) => comment.id !== id));
        setDeleteComment(null);
      });
    };
    return (
      <>
        <Button
          style={{ marginTop: 10 }}
          type="primary"
          onClick={() => setAddMode(!addMode)}
        >
          {addMode
            ? t(TRANSLATIONS.CANCEL.key)
            : t(TRANSLATIONS.ADD_COMMENT.key)}
        </Button>
        {addMode && (
          <div>
            <h4>{t(TRANSLATIONS.ADD_COMMENT.key)}</h4>
            <TextArea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              rows={4}
              required={true}
            />
            <Button type="primary" onClick={submitComment}>
              {t(TRANSLATIONS.ADD_COMMENT.key)}
            </Button>
          </div>
        )}
        {/* comments */}
        <List
          itemLayout="horizontal"
          dataSource={testComments}
          locale={{ emptyText: "No comments" }}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
                  />
                }
                title={
                  <span>
                    {`${item.user}`}
                    <span style={{ fontSize: 10 }}>
                      ({new Date(item.created_date).toLocaleString()})
                    </span>
                    <span style={{ fontSize: 10 }}>(id:{item.id})</span>
                  </span>
                }
                description={item.comment}
              />
              {item.user === user.email && !deleteComment && (
                <div>
                  <Button
                    style={{ marginLeft: 10 }}
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => setDeleteComment(item.id)}
                  ></Button>
                </div>
              )}
              {item.user === user.email && deleteComment === item.id && (
                <div>
                  <Button
                    icon={<LeftOutlined />}
                    onClick={() => setDeleteComment(null)}
                  >
                    {t(TRANSLATIONS.CANCEL.key)}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    danger
                    icon={<CheckOutlined />}
                    onClick={() => apiDeleteComment(item.id)}
                  >
                    {t(TRANSLATIONS.CONFIRM.key)}
                  </Button>
                </div>
              )}
            </List.Item>
          )}
        />
      </>
    );
  };
  const TestComponent = ({ test, comments }) => {
    return (
      <div>
        {test.state === "failed" && (
          <div>
            <h4 style={{ color: "red" }}>{t(TRANSLATIONS.FAILURE_MSG.key)}:</h4>
            <p style={{ color: "red" }}>{test.err.message}</p>
            <h4>{t(TRANSLATIONS.FAILURE_STACK.key)}:</h4>
            <pre>
              <code>{test.err.estack}</code>
            </pre>
            <hr></hr>
          </div>
        )}

        <TestCommentsComponent test={test} comments={comments} />
      </div>
    );
  };
  const compactFilePath = (path) => {
    const parts = path.split("/");
    return parts[parts.length - 1];
  };
  const testsColumns = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      sorter: (a, b) => a.file.localeCompare(b.file),
      render: (file) => {
        return <span>{compactFilePath(file)}</span>;
      },
    },
    {
      title: "Suites",
      dataIndex: "suites",
      key: "suites",
      render: (suites) => {
        const items = suites.map((suite, i) => {
          const tests = [];
          suite.tests.forEach((test) => {
            tests.push(test);
          });
          console.log("NEWtests", tests);
          const extractStatus = (suite) => {
            if (suite.failures.length > 0) return "failed";
            if (suite.passes.length > 0) return "passed";
            return "skipped";
          };
          const item = {
            label: suite.title,
            key: i,
            tests: tests,
            status: extractStatus(suite),
          };
          return item;
        });
        const getHeader = (suite) => {
          const color = (suite) => {
            if (suite.status === "failed") return "red";
            if (suite.status === "passed") return "green";
            return "grey";
          };
          return <span style={{ color: color(suite) }}>{suite.label}</span>;
        };
        return (
          <Collapse>
            {items.map((item) => (
              <Collapse.Panel header={getHeader(item)} key={item.key}>
                <Collapse>
                  {item.tests.map((test) => {
                    const getTestHeader = (test) => {
                      const color = (test) => {
                        if (test.state === "failed") return "red";
                        if (test.state === "passed") return "green";
                        return "grey";
                      };
                      return (
                        <span style={{ color: color(test) }}>
                          {test.title} ({test.state})
                        </span>
                      );
                    };

                    return (
                      <Collapse.Panel
                        header={getTestHeader(test)}
                        key={test.uuid}
                      >
                        <TestComponent test={test} comments={comments} />
                      </Collapse.Panel>
                    );
                  })}
                </Collapse>
              </Collapse.Panel>
            ))}
          </Collapse>
        );
      },
    },
    // {
    //   title: "Title",
    //   dataIndex: "title",
    //   key: "title",
    //   sorter: (a, b) => a.title.localeCompare(b.title),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "state",
    //   key: "state",
    //   sorter: (a, b) => a.state.localeCompare(b.state),
    //   render: (status) => {
    //     let color = "green";
    //     if (status === "failed") {
    //       color = "red";
    //     } else if (status === "skipped") {
    //       color = "grey";
    //     }
    //     return <Tag color={color}>{status}</Tag>;
    //   },
    // },
    // {
    //   title: "Duration",
    //   dataIndex: "duration",
    //   key: "duration",
    //   sorter: (a, b) => a.duration - b.duration,
    //   render: (duration) => {
    //     return <span>{duration} ms</span>;
    //   },
    // },
  ];
  // pytest section
  const CrashComponent = ({ call }) => {
    const fileNameFromPath = (path) => {
      const spl = path.split("/");
      const filename = spl.pop();
      const folder = spl.pop();
      const root = spl.pop();
      return `${root}/${folder}/${filename}`;
    };
    const [showRepr, setShowRepr] = useState(false);
    return (
      <>
        {call?.crash && (
          <>
            <pre style={{ color: "red" }}>
              {fileNameFromPath(call.crash.path)}:{call.crash.lineno}
            </pre>
            <pre style={{ color: "red" }}>{call.crash.message}</pre>
            {showRepr && (
              <Button
                type="primary"
                onClick={() => {
                  console.log("toggle");
                  setShowRepr(false);
                }}
              >
                Hide repr
              </Button>
            )}
            {!showRepr && (
              <Button
                type="primary"
                onClick={() => {
                  console.log("toggle");
                  setShowRepr(true);
                }}
              >
                Show repr
              </Button>
            )}
            {showRepr && <pre style={{ color: "" }}>{call.longrepr}</pre>}
          </>
        )}
      </>
    );
  };
  const pytestColumns = [
    {
      title: "Test",
      dataIndex: "nodeid",
      key: "nodeid",
      sorter: (a, b) => a.file.localeCompare(b.file),
      render: (file) => {
        return <span>{compactFilePath(file)}</span>;
      },
    },
    {
      title: "Result",
      dataIndex: "outcome",
      key: "outcome",
      render: (outcome) => {
        const color = (outcome) => {
          if (outcome === "passed") return "green";
          if (outcome === "failed") return "red";
          return "grey";
        };
        return <Tag color={color(outcome)}>{outcome}</Tag>;
      },
    },
    {
      title: "Call",
      dataIndex: "call",
      key: "call",
      render: (call, record, index) => {
        return (
          <>
            {call?.crash && <CrashComponent call={call} />}
            <br></br>
            <TestCommentsComponent test={record} comments={comments} />
          </>
        );
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        {loading && <h1>{t(TRANSLATIONS.LOADING.key)}</h1>}
        {report && report.type === "PYTEST" && !loading && (
          <>
            <Row>
              <Col span={24}>
                <Flex
                  gap="middle"
                  align="center"
                  justify="left"
                  style={{ marginTop: 10 }}
                >
                  <Link to={`/projects/${report.project}`}>
                    {t(TRANSLATIONS.GO_TO_PROJECT.key)}
                  </Link>
                  <Link target="_blank" to={`${report.ci_job_url}`}>
                    {t(TRANSLATIONS.GO_TO_CI_PIPELINE.key)}
                  </Link>
                </Flex>
                <Flex gap="middle" align="center" justify="space-between">
                  <div>
                    <h2>
                      {t(TRANSLATIONS.REPORT.key)} id: {id} ({report.type}){" "}
                    </h2>
                    <h2>{report.created_date}</h2>

                    <Flex gap="small" wrap>
                      {report.tags.map((tag, i) => (
                        <Tag
                          color="geekblue"
                          key={i}
                          style={{ margin: "0 5px" }}
                        >
                          {tag}
                        </Tag>
                      ))}
                    </Flex>
                  </div>
                  <h3>
                    <span>Tests: {report.data.summary.total}</span>
                    <span> | </span>
                    <span style={{ color: "green" }}>
                      Passes: {report.data.summary.passed}
                    </span>
                    <span> | </span>
                    <span style={{ color: "red" }}>
                      Failures: {report.data.summary.failed}
                    </span>
                  </h3>
                </Flex>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  id="agent-teams"
                  pagination={false}
                  size="small"
                  columns={pytestColumns}
                  dataSource={allTests ?? []}
                  loading={loading}
                  showSorterTooltip={true}
                  locale={{
                    triggerAsc: `${t(
                      TRANSLATIONS.SORTING_ASCENDING_LABEL.key
                    )}`,
                    triggerDesc: `${t(
                      TRANSLATIONS.SORTING_DESCENDING_LABEL.key
                    )}`,
                    cancelSort: `${t(TRANSLATIONS.SORTING_CANCEL_LABEL.key)}`,
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={`${t(TRANSLATIONS.EMPTY_LIST_TITLE.key)}`}
                      />
                    ),
                  }}
                  rowKey="uuid"
                />
              </Col>
            </Row>
          </>
        )}
        {report && report.type === "MOCHAWESOME" && !loading && (
          <Row>
            <Col span={24}>
              <Flex
                gap="middle"
                align="center"
                justify="left"
                style={{ marginTop: 10 }}
              >
                <Link to={`/projects/${report.project}`}>
                  {t(TRANSLATIONS.GO_TO_PROJECT.key)}
                </Link>
                <Link target="_blank" to={`${report.ci_job_url}`}>
                  {t(TRANSLATIONS.GO_TO_CI_PIPELINE.key)}
                </Link>
              </Flex>
              <Flex gap="middle" align="center" justify="space-between">
                <div>
                  <h2>
                    {t(TRANSLATIONS.REPORT.key)} id: {id} ({report.type}){" "}
                  </h2>
                  <h2>{report.created_date}</h2>

                  <Flex gap="small" wrap>
                    {report.tags.map((tag, i) => (
                      <Tag color="geekblue" key={i} style={{ margin: "0 5px" }}>
                        {tag}
                      </Tag>
                    ))}
                  </Flex>
                </div>
                <h3>
                  <span>Suites: {report.data.stats.suites}</span>
                  <span> | </span>
                  <span>Tests: {report.data.stats.tests}</span>
                  <span> | </span>
                  <span style={{ color: "green" }}>
                    Passes: {report.data.stats.passes}
                  </span>
                  <span> | </span>
                  <span style={{ color: "red" }}>
                    Failures: {report.data.stats.failures}
                  </span>
                </h3>
              </Flex>
              <Row style={{ marginTop: 15 }}>
                <Col span={24}>
                  <Table
                    id="agent-teams"
                    pagination={false}
                    size="small"
                    columns={testsColumns}
                    dataSource={allTests ?? []}
                    loading={loading}
                    showSorterTooltip={true}
                    locale={{
                      triggerAsc: `${t(
                        TRANSLATIONS.SORTING_ASCENDING_LABEL.key
                      )}`,
                      triggerDesc: `${t(
                        TRANSLATIONS.SORTING_DESCENDING_LABEL.key
                      )}`,
                      cancelSort: `${t(TRANSLATIONS.SORTING_CANCEL_LABEL.key)}`,
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={`${t(
                            TRANSLATIONS.EMPTY_LIST_TITLE.key
                          )}`}
                        />
                      ),
                    }}
                    rowKey="uuid"
                  />
                </Col>
              </Row>

              {/* <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <hr></hr>
              <p>{JSON.stringify(report)}</p> */}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
